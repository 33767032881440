<template>
  <div class="customer_sec">
    <div class="flex ">
      
        <div class="left_section display"></div>
   
      <div class="customer_signup">
         <div class="right_sec remove_center_align">
           <div class="form_head">
            <img src="../../assets/images/visitormatch-logo.png" alt="logo" width="145">
            <h1 class="sign_header">Reset Password</h1>
           <form data-vv-scope="resetPasswordForm" @submit.prevent>
             <ul class="input_listing c_header_Sec">
              
              <li>
                <div class="input_sec">
                <input
                  :class="isErrorExist(errors, 'password') ? 'error-customer-field' : ''"
                  type="password"
                  name="password"
                  v-model="password"
                  v-validate="'required'"
                  ref="passwordref"
                  placeholder="Password"
                  class="w-full">
                  <b-icon v-show="isErrorExist(errors, 'password')" icon="exclamation-circle-fill"  font-scale="1.5" class="text-white"></b-icon>
                </div>
                <span class="error_msg mb-0">{{ errors.first("resetPasswordForm.password")}}</span>
              </li>
              <li>
               <div class="input_sec">
                 <input
                  :class="isErrorExist(errors, 'confirmPassword') ? 'error-customer-field' : ''"
                  type="password"
                  name="confirmPassword"
                  v-model="confirmPassword"
                  v-validate="'required|confirmed:passwordref'"
                  placeholder="Confirm Password"
                  class="w-full">
                  <b-icon v-show="isErrorExist(errors, 'confirmPassword')" icon="exclamation-circle-fill"  font-scale="1.5" class="text-white"></b-icon>
               </div>
                  <span class="error_msg mb-0">{{errors.first("resetPasswordForm.confirmPassword")}}</span>
              </li>
            </ul>
            <button class="signin pass_btn" @click="setPassword()"><span class="d-flex align-center gap-2"> <img v-show="isLoader" src="../../assets/images/loadar.svg" width="20px" alt="loader" class="animate-spin">Reset Password</span> </button>
           </form>
            <div class="text-center mt-5">
              <router-link
              to="Login"
              class="link_redirect mt-8 font_w_600"
              >Return to Login </router-link
            >
            </div>
          
          </div>
         </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";

const dict = {
  custom: {
    password: {
      required: "The email field is required",
    },
    confirmPassword: {
      confirmed: "Password and confrim password does not match.",
      required: "The confrim password field is required",
    },
  },
};

Validator.localize("en", dict);
export default {
  data() {
    return {
      password: null,
      confirmPassword: null,
      code: null,
      isLoader: false
    };
  },
  mounted() {
    if (this.$route.query.reset) {
      this.code = this.$route.query.reset;
    } else {
      this.$router.push({
        name: "page-login",
      });
    }
  },
  methods: {

    isErrorExist(e, field){
      let errorObj = e.items.find(o => o.field === field);
      if(errorObj){
        return true
      }
    },
    setPassword() {
      this.$validator.validateAll("resetPasswordForm").then((result) => {
        if (result) {
          // this.$vs.loading();
          this.isLoader = true
          let url = "/Account/ResetPassword";
          let payload = {
            Password: this.password,
            ConfirmPassword: this.confirmPassword,
            Code: this.code,
          };
          this.axios.post(url, payload).then((response) => {
            let data = response.data;
            if (data.Success) {
              this.$vs.notify({
                title: "Success",
                text: data.Message,
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
            } else {
              this.$vs.notify({
                title: "Error",
                text: data.Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            }
            // this.$vs.loading.close();
            this.isLoader = false
          }).catch(() => {
            // this.$vs.loading.close();
            this.isLoader = false
          });
        }
      });
    },
  },
};
</script>

<style></style>
