<template>
  <div>
    <!-- <p>Loading...</p>
    <div id="loading-bg">
      <div class="loading mt-0">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div> -->
    <reset-password-defualt
      v-if="isDefaultLogin == true"
    ></reset-password-defualt>
    <reset-password-reseller
      v-if="isDefaultLogin == false"
    ></reset-password-reseller>
    <reset-password-customer v-if="isCustomer"></reset-password-customer>
  </div>
</template>

<script>
import ResetPasswordDefualt from "../../components/reset-password/ResetPasswordDefualt.vue";
import ResetPasswordReseller from "../../components/reset-password/ResetPasswordReseller.vue";
import ResetPasswordCustomer from "../../components/reset-password/ResetPasswordCustomer.vue";

export default {
  components: {
    ResetPasswordDefualt,
    ResetPasswordReseller,
    ResetPasswordCustomer
  },
  data() {
    return {
      isDefaultLogin: null,
      isCustomer: null
    };
  },
  created() {
    if(location.host.includes('visitormatch.com')){
      this.isCustomer = true
      var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = "/favicon/favicon-generic.png";
      document.getElementsByTagName("head")[0].appendChild(link);
    }else{
      this.getUserType();
    }
  },
  methods: {
    getUserType() {
      this.$vs.loading();
      let url = "Account/GetLoginPageInfoV2";
      url += "?id=" + location.host;
      // url += '?id=visualvisitor.zignuts.com';
      this.axios
        .get(url)
        .then((response) => {
          let data = response.data;
          this.isDefaultLogin = data.Default_Login_Page;
          if (!this.isDefaultLogin) {
            var link =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = "/favicon/favicon-generic.png";
            document.getElementsByTagName("head")[0].appendChild(link);
            this.vendastaLogin(
              this.code,
              this.scope,
              this.state,
              data.PartnerProductId,
              this.accountId
            );
          } else {
            var link2 =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link2.type = "image/x-icon";
            link2.rel = "shortcut icon";
            link2.href = "/favicon/favicon.icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e, "#user-login");
        });
    },
  },
};
</script>
