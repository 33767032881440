<template>
  <div
    class="flex w-full vx-row no-gutter flex-wrap items-end justify-center login-main"
    id="page-login"
  >
    <span class="top-bg-overly"
      ><img src="../../assets/images/login-left.png"
    /></span>
    <div class="container">
      <div class="vx-row justify-center">
        <div class="vx-col md:w-3/4 w-full">
          <div class="vx-card__title login-title">
            <h2>
              <span>Welcome to</span> <br />
              Visual Visitor.
            </h2>
          </div>
        </div>
      </div>
      <div class="vx-row justify-center">
        <div class="vx-col login-form-info md:w-3/4 w-full">
          <vx-card>
            <div class="vx-row justify-center items-center">
              <!-- Login Image -->
              <div class="vx-col hidden lg:block lg:w-7/12 order-2">
                <img
                  src="@/assets/images/login-right.svg"
                  alt="login"
                  class="mx-auto login-img"
                />
              </div>
              <!-- Login Form -->
              <div class="vx-col w-full lg:w-5/12 d-theme-dark-bg order-1">
                <div class="p-4 login-tabs-container">
                  <div class="vx-card__title mb-2">
                    <h4>Reset Password</h4>
                  </div>
                  <div>
                    <form data-vv-scope="resetPasswordForm">
                      <div>
                        <vs-input
                          type="password"
                          name="password"
                          label-placeholder="Password"
                          v-model="password"
                          v-validate="'required'"
                          ref="passwordref"
                          class="w-full"
                          icon="icon icon-lock"
                          icon-pack="feather"
                          icon-no-border
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("resetPasswordForm.password")
                        }}</span>
                      </div>
                      <div>
                        <vs-input
                          type="password"
                          name="confirmPassword"
                          label-placeholder="Confirm Password"
                          v-model="confirmPassword"
                          v-validate="'required|confirmed:passwordref'"
                          class="w-full"
                          icon="icon icon-lock"
                          icon-pack="feather"
                          icon-no-border
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("resetPasswordForm.confirmPassword")
                        }}</span>
                      </div>
                      <vs-button
                        color="secondary"
                        id="user-login"
                        :disabled="button_loading"
                        type="filled"
                        class="w-full float-left mt-6"
                        @click="setPassword()"
                        >Reset My Password</vs-button
                      >
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
          <router-link to="Login" class="forgot-pass"
            >Return to Login <feather-icon icon="ArrowRightIcon"></feather-icon
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Validator } from "vee-validate";

const dict = {
  custom: {
    password: {
      required: "Please enter valid password.",
    },
    confirmPassword: {
      confirmed: "Password and confrim password does not match.",
      required: "Please enter confirm password.",
    },
  },
};

Validator.localize("en", dict);

export default {
  data() {
    return {
      password: null,
      confirmPassword: null,
      code: null,
    };
  },
  mounted() {
    if (this.$route.query.reset) {
      this.code = this.$route.query.reset;
    } else {
      this.$router.push({
        name: "page-login",
      });
    }
  },
  methods: {
    setPassword() {
      this.$validator.validateAll("resetPasswordForm").then((result) => {
        if (result) {
          this.$vs.loading();
          let url = "/Account/ResetPassword";
          let payload = {
            Password: this.password,
            ConfirmPassword: this.confirmPassword,
            Code: this.code,
          };
          this.axios
            .post(url, payload)
            .then((response) => {
              let data = response.data;
              if (data.Success) {
                this.$router.push({
                  path: "/pages/login",
                  query: { msg: data.Message },
                });
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: data.Message,
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  position: "top-right",
                  time: 4000,
                });
              }
              this.$vs.loading.close();
            })
            .catch(() => {
              this.$vs.loading.close();
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
input::-webkit-input-placeholder {
  color: rgba(var(--vs-primary), 1);
  opacity: 1;
}
input:-ms-input-placeholder {
  color: rgba(var(--vs-primary), 1);
  opacity: 1;
}
input::placeholder {
  color: rgba(var(--vs-primary), 1);
  opacity: 1;
}
.input-span-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
}
.login-form {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
  z-index: 1;
  padding: 32px 0px;
  .login-content {
    float: left;
    width: calc(100% - 10%);
    margin-left: 10%;
    background: #ffffff;
    box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.15);
    border-radius: 1em;
    padding: 32px 48px;
    position: relative;
    &:after {
      position: absolute;
      left: -100px;
      top: 32px;
      height: 212px;
      width: 212px;
      border-radius: 100%;
      background: rgba(205, 205, 205, 0.15);
      content: "";
      z-index: -1;
    }
    h1 {
      float: left;
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .vs-input--label {
      padding-left: 0px;
      font-weight: 500;
      color: #666666;
      margin-bottom: 8px;
      float: left;
      width: 100%;
    }
    .vs-con-input {
      float: left;
      width: 100%;
      .vs-input--input {
        border: 0px !important;
        border-radius: 8px !important;
        background: rgba(var(--vs-primary), 0.1);
        color: rgba(var(--vs-primary), 1);
        font-weight: 500;

        &:focus {
          box-shadow: none;
        }
      }
    }
    .vs-input--icon.icon-after {
      border: 0px;
      font-weight: 600;
      color: rgba(var(--vs-primary), 1);
    }
    .con-slot-label {
      font-size: 12px;
      color: #333;
      font-weight: 500;
      margin-left: 4px;
    }
    .vs-button {
      border-radius: 8px;
      padding: 6px 30px;
    }
    .login-icon {
      background: rgba(var(--vs-primary), 1);
      width: 56px;
      height: 56px;
      border-radius: 100%;
      float: left;
      text-align: center;
      position: absolute;
      left: -30px;
      top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      box-shadow: 0px 0px 10px 2px rgba(var(--vs-primary), 0.2);
      img {
        max-width: 22px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .logo-login {
    float: left;
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    .dashboard-img {
      width: calc(100% - 14%);
      background: #ffffff;
      border-radius: 0.4em;
      float: right;
      margin-left: 14%;
    }
    .top-circles {
      position: absolute;
      left: 35%;
      top: -60px;
      width: 70px;
      height: 70px;
      background: rgba(var(--vs-primary), 0.1);
      content: "";
      border-radius: 100%;
      &:after {
        position: absolute;
        left: -13px;
        bottom: -1px;
        width: 40px;
        height: 40px;
        background: rgba(205, 205, 205, 0.3);
        content: "";
        border-radius: 100%;
      }
    }
    h5 {
      position: absolute;
      right: -25%;
      bottom: -90px;
      font-size: 140px;
      text-transform: capitalize;
      font-weight: 700;
      color: rgba(205, 205, 205, 0.2);
      &:after {
        position: absolute;
        left: 111px;
        top: 76px;
        width: 38px;
        height: 40px;
        background: rgba(var(--vs-primary), 1);
        content: "";
        border-radius: 100%;
        box-shadow: 0px 15px 32px 8px rgba(var(--vs-primary), 0.3);
      }
    }
  }
}
@media (max-width: 1199px) {
  .login-form .logo-login {
    display: none;
  }
  .login-form .login-content {
    margin-left: 100px;
    width: calc(100% - 100px);
  }
}
@media (max-width: 991px) {
}
@media (max-width: 575px) {
  .login-form .login-content {
    padding: 32px;
  }
}
@media (max-width: 480px) {
  .login-form .login-content {
    margin-left: 0px;
    width: calc(100% - 0px);
    margin-top: 32px;
    padding-top: 56px;
  }
  .login-form .login-content .login-icon {
    right: 0px;
    left: 0px;
    top: -30px;
    margin: auto;
  }
}
</style>
